@mixin barlow-regular {
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin barlow-medium {
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 500;
}

@mixin barlow-semibold {
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 600;
}


@mixin lato-regular {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin lato-medium {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 500;
}

@mixin lato-semibold {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

@mixin transition-basic {
  transition: all .15s ease-in-out;
}

//space --> margin

//margin-top
@mixin mt-8 {
  margin-top: 8px;
}

@mixin mt-16 {
  margin-top: 16px;
}

@mixin mt-24 {
  margin-top: 24px;
}

@mixin mt-32 {
  margin-top: 32px;
}

@mixin mt-40 {
  margin-top: 40px;
}

@mixin mt-48 {
  margin-top: 48px;
}

@mixin mt-56 {
  margin-top: 56px;
}

@mixin mt-64 {
  margin-top: 64px;
}

//margin-bottom

@mixin mb-8 {
  margin-bottom: 8px;
}

@mixin mb-16 {
  margin-bottom: 16px;
}

@mixin mb-24 {
  margin-bottom: 24px;
}

@mixin mb-32 {
  margin-bottom: 32px;
}

@mixin mb-40 {
  margin-bottom: 40px;
}

@mixin mb-48 {
  margin-bottom: 48px;
}

@mixin mb-56 {
  margin-bottom: 56px;
}

@mixin mb-64 {
  margin-bottom: 64px;
}

//margin-right
@mixin mr-8 {
  margin-right: 8px;
}
@mixin mr-16{
  margin-right: 16px;
}
@mixin mr-24 {
  margin-right: 24px;
}
@mixin mr-32 {
  margin-right: 32px;
}
@mixin mr-40 {
  margin-right: 40px;
}
@mixin mr-48 {
  margin-right: 48px;
}

//margin-left
@mixin ml-8 {
  margin-left: 8px;
}

@mixin ml-16 {
  margin-left: 16px;
}

@mixin ml-24 {
  margin-left: 24px;
}

@mixin ml-32 {
  margin-left: 32px;
}

@mixin ml-40 {
  margin-left: 40px;
}

@mixin ml-48 {
  margin-left: 48px;
}

@mixin ml-56 {
  margin-left: 56px;
}

//space --> padding

//padding top

@mixin pt-8 {
  padding-top: 8px;
}

@mixin pt-16 {
  padding-top: 16px;
}

@mixin pt-24 {
  padding-top: 24px;
}

@mixin pt-32 {
  padding-top: 32px;
}

@mixin pt-40 {
  padding-top: 40px;
}

@mixin pt-48 {
  padding-top: 48px;
}

//padding bottom

@mixin pb-8 {
  padding-bottom: 8px;
}

@mixin pb-16 {
  padding-bottom: 16px;
}

@mixin pb-24 {
  padding-bottom: 24px;
}

@mixin pb-32 {
  padding-bottom: 32px;
}

@mixin pb-40 {
  padding-bottom: 40px;
}

@mixin pb-48 {
  padding-bottom: 48px;
}

@mixin pb-72 {
  padding-bottom: 72px;
}


//padding right 

@mixin pr-8 {
  padding-right: 8px;
}

@mixin pr-40{
  padding-right: 40px;
}

//padding left 

@mixin pl-8 {
  padding-left: 8px;
}

@mixin pl-24 {
  padding-left: 24px;
}

@mixin pl-32 {
  padding-left: 32px;
}

@mixin pl-40 {
  padding-left: 40px;
}