@import '../fonts/barlow/barlow.css';
@import '../fonts/lato/lato.css';

h1 {
  font-size: 50px;
  line-height: 56px;
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

h2 {
  font-size: 35px;
  line-height: 40px;
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: $dark-grey;
}

h3 {
    font-size: 25px;
    line-height: 32px;
    font-family: 'Barlow', Arial, Helvetica, sans-serif;
    font-weight: 600;
    }

h4 {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-weight: 500;
  }

p {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
