.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include lato-semibold;
      font-size: 100px;
      line-height: 1;
      letter-spacing: 0.05em;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
    }

    p {
      margin: 3px 0 25px;
    }
}

.blog-date {
  margin-top:20px;
}

.blog-date-archive {
  cursor: pointer;

  &:hover{
    color:$first-color;
  }
}

.cms-page{
  margin:20px 0px 20px 0px;

  u {
    text-decoration: underline;
  }
  
  h1{
    @include lato-semibold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 20px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h2{
    @include lato-semibold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 16px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h3,h4{
    @include lato-semibold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 14px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  
  
  ul{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  ol{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
}

.cms-cover-title{
  color: $white;
  font-size:40px;
  margin-top:30px;
  @include breakpoint(small down) {
   font-size:30px;
  }

}


a.blog-post-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.read-more {
  color: $first-color;
  font-size: 14px;
  @include lato-semibold;
  text-transform: uppercase;
}

.home-blog {
  background-color: $light-grey;
  min-height: 470px;
}

.blog-text-container {
  padding: 16px;
}


.blog-post-wrapper {
  a {
    color: $first-color;
    @include lato-semibold;
  }

  p {
    @include mb-8;
  }

  ul {
    @include mt-16;
    li {
      list-style-type: disc;
      @include ml-24;

    }
  }
}

//4 comuns image
.img-4-comuns {
  width: 100%;
}

.date {
  font-size: 14px;
  @include lato-medium;
  @include mt-8;
}

.description-blog {
  font-size: 16px;
  padding-top: 10px;
  text-align: left;
}


.blog-post-text{
  text-align:lef   t;

  h1{
    font-size: 40px;
    text-align: center;
  }

  h2{
    font-size: 30px;
  }

  h3{
    font-size: 24px;
  }
}

.blog-post-summary-container {

  &:not(.video-div) {

  &:hover {
    .blog-post-image div { 
      transform: scale(1.1); 
    }
  }

  }

}

.blog-post-summary-container .blog-post-image div{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: inherit;
    transition: all .3s ease-out;
}

.blog-post-summary-container .blog-post-image {
  height: 250px;
  overflow: hidden;
  @include breakpoint(small down) {
    height: 110px;
  }
}

.blog-post-summary-container .blog-post-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 2px 20px hsla(0,0%,41%,.14);
}

.blog-post-summary-container .blog-post-text {
  margin-top: 15px;
}

.pagination a {
  @include lato-regular;
  transition: all .15s ease-in-out;
  border:none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination a:hover {
  background-color: $first-color;
  color: $white;
}

.pagination .active a, .pagination a:hover {
  border-radius: 50%;
  background-color: $first-color;
  color: $white;
}

.space-30-responsive{
  margin-bottom: 30px;
  @include breakpoint(small down) {
    margin-bottom: 10px;
  }
}


//blog article

.title-article-blog{
  text-align:center;
  font-size: 35px;
  line-height: 40px;
  color: $dark-grey;
}
.text-article-blog {
  text-align: left;
}

.blog-post-date {
  text-align: center;
  margin: 24px 0;
}

.blog-post-image {
  margin: 24px 0;
  text-align: center;
}

.summary-blog {
  text-align: center;
  @include lato-semibold;
  @include mb-16;
  color: $dark-grey;
}


.first-post-archive {
  height: 50vh;
  background-color: $light-grey;
}

.centered-padding-paragraph{
  padding: 0 20%;
  @include breakpoint(small down) {
    padding: 0px;
  }
}

#blog-banner-cms {

  @include breakpoint(medium down) {
    order: 2;
    text-align: center;
  }

  .item {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

  .item-1 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 10px 0px 0px;
    }
  }

  .item-2 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 0px 0px 10px;
    }
  }

  img {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

}

.blog-social__button {
  transition: all .15s ease-out;
  border: 1px solid;
  border-radius: 2px;
  padding: 13px 40px;
  position: relative;
  display: inline-block;
}

#text-block-banner-1, #text-block-banner-2 {

  margin-left: 10px;

  @include breakpoint(small down) {
    margin-left: 0px;
  }
}

#last-post {

  @include breakpoint(small down) {
    margin-bottom: 10px;
  }

}

#map {
  height: 100%;
}

.map-container {

  padding: 10px 0 10px 10px;

  @include breakpoint(small down) {
    padding: 0px;
  }

}

.store-overflow {
  overflow: auto;
  height: calc(100vh - 152px);

  @include breakpoint(small down) {
    overflow: unset;
    height: auto;
  }

}


#blog-banner-1 {
  height: 25vh;
  margin-bottom: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-bottom: 0px;
  }

  @include breakpoint(small down) {
    padding-bottom: 5px;
  }


}

#blog-banner-2 {
  height: 24vh;
  margin-top: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-top: 0px;
  }

  @include breakpoint(small down) {
    padding-top: 5px;
  }
}

.text-center{
  text-align:center;
}


 .debug .hot-spot .tooltip {
   display: block;
 }


.text-banner-home {
  background-color: $white;
  padding: 16px 72px;
  position: absolute;
  bottom: -21px;
  text-align: center;
  margin: 0 24px;
    @include breakpoint(medium down) {
      padding: 16px 24px;
    } 
  &.full {
    bottom: 24px;
    right: 32px;
      @include breakpoint(small down) {
      bottom: -100px;
      right: 0;
      }
  }
}

.banner-12-home {
  @include breakpoint(small down) {
    @include pb-72;
  } 
}

.banner-home-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.second {
    margin: -100px 0;
    @include mb-40;
    @include breakpoint(small down) {
      margin: 24px 0;
    } 
  }
}

.category-home {
    &.container {
      @include breakpoint(small down) {
        @include mt-24;
      } 
    }
    img {
      border-radius: 4px 4px 0 0;
      width: 500px;
    }

    &.text {
    padding: 16px;
    background-color: $white;
    }

    h3 {
      text-transform: uppercase;
      @include mb-8;
      &::after {
        content: url(/skins/current-skin/images/icons/arrow-right.svg);
        width: 8px;
        margin-left: 10px;
        right: -16px;
        top: 25px;
      }
  }
}

.title-paragraph-page {
  color: $first-color;
  @include mt-24;
  @include mb-8;
  @include barlow-medium;
  text-transform: uppercase;
}

.align-center-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}