.product-item {
  position: relative;
  text-align: center;
  background-color: $white;
  min-height: 420px;
  padding: 0 8px;
  @include breakpoint(medium down) {
    min-height: 320px;
  }
  &.list {
    min-height: 320px;
  }

  .product-item__img img+img,
  .product-item__img picture+picture {
    opacity: 1;
  }
}


.close-popup {
  position: absolute;
  right: 16px;
  width: 16px;
  cursor: pointer;
}

.product-item__img {
  position: relative;
 @include mb-16;

  img+img,
  picture+picture {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
  }
}

.product-item__brand {
  @include barlow-medium;
  font-size: 24px;
  line-height: 32px;
  @include mb-8;
  @include breakpoint(small down) {
    font-size: 18px;
  }
}

.product-item__retail-price {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $dark-grey;
  margin-top: -1px;
  display: block;
}

.product-item__price-block {
 @include pt-8;
 @include pb-16;

  span.discount{
    color: $dark-grey;
    font-size: 20px;
    margin-left: 10px;
    text-transform: inherit;

    &.linedthrough{
      text-decoration: line-through;
    }
  }
}

.product-item__link {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0px 2px 20px rgba(105, 105, 105, 0.14);
  border-radius: 4px;
  z-index: 2;
  &:hover {
    box-shadow: 0 2px 20px hsla(0,0%,41%,.24);
  }

  &.list {
    min-height: 320px;
  }
}

.product-item__table {
  @include breakpoint(small down) {
    margin: 10px 0px;
  }
}

.product-item__description {
  border-top: 1px solid $line-color;
  padding-top: 15px;
}

.product-code{
  font-size:14px;
}

//catalog griglia

.rate-price{
  .save{
    text-align: left;
    font-size: 18px;
    margin-top: 4px;
    color: $dark-grey;
    text-transform: inherit;
    letter-spacing: 0.3px;
  }
  .price{
    color: $dark-grey;
    font-size: 18px;
    margin-left: 10px;
  }
}

.clear-options {
  color: $first-color;
  @include mb-16;
  cursor: pointer;
}

//bollino promo
.stiker-promo{
  width: 48px;
  height: 48px;
  background-color: $first-color;
  position: absolute;
  z-index: 3;
  border-radius: 50px;
  right: 1rem;
  top: 1.5rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;

  &.grid{
    right: 0px;
    top: 0.5rem;
  }
}

.text-promo{
    color: $white;
    font-size: 17px;
    @include barlow-medium;

    &.white{
      color: $white;
    }
}