.profile-container {
  padding: 95px 0 60px;
  @include breakpoint(small down) {
    padding: 0 0 60px;
  }
}

.profile-nav {
    text-transform: uppercase;
    font-size: 14px;
    a{
      @include transition-basic;
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.1em;
      margin-bottom: 0;
      margin-top: 12px;
      @include breakpoint(small down) {
        margin: 10px 0;
      }
      &:hover, &:active {
        color: $first-color;
        @include lato-semibold;
      }
      &.active {
        color: $first-color;
        @include lato-semibold;
      }
  }
}
  
.profile-title {
  @include barlow-medium;
  font-size: 28px;
  @include mb-24;
  @include breakpoint(small down) {
    font-size: 24px;
  }
  &.consent {
    @include mb-8;
  }
}
ul.profile-nav.help-menu.light-18 li > a.active{
  color: $first-color;
  @include lato-semibold;
}

.profile-separator {
  border: none;
  border-top: 1px solid $line-color;
  margin-top: 40px;
}

.responsive-table {
  overflow-x: auto;
}


.order-row {
  @include transition-basic;
  cursor: pointer;
    &:hover,
    &.selected {
      background-color: $light-grey;
    }

    td {
      border-bottom: 1px solid $line-color;
      border-top: 1px solid $line-color;
      padding: 10px 0 8px;
      font-size: 16px;
      letter-spacing: 0.05em;
      text-align:left;
      @include breakpoint(small down) {
        font-size: 14px; 
        }
    }
}

.order-row-indicator-container {
  width:30px;
  position: relative;
}

.order-controls-row {
  margin-bottom: 10px;
}

.order-row-indicator {
  @include transition-basic;
  width: 8px;
  position: absolute;
  top: 13px;
  left: 4px;

    &.fa-rotate-90 {
      transform: rotate(90deg);
    }
}

.order-controls {
  display: none;
  border-left: 1px solid $first-color;

    > td {
      padding: 20px 20px;
      
    }

    h4 {
      @include lato-semibold;
      margin-bottom: 15px;
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
    }
}

.export-files {
  margin-bottom: 8px;

  .btn {
    margin-right: 16px;
  }
}

.dispatch-data-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 2px 0;

    > div {
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.order-controls-return {
  border-collapse: collapse;

    td {
      border-top: 1px solid $line-color;
      padding: 8px;
      line-height: 1.43;
      vertical-align: middle;
    }

    .product-sku {
      font-size: 12px;
      letter-spacing: 0.1em;
      margin-top: 5px;
    }
}

.new-return-request form {
  margin: 40px 0;
}

.new-return-request {
  display: none;
}

.returned-goods-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

    div {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.returned-goods-item {
  border-bottom: 1px solid $line-color;
  padding: 15px 0;
}

.cancel-return-request-form {
  @include breakpoint(small down) {
    @include mt-8;
  }
}
.myorders{
  border-bottom: 1px solid $line-color;
  padding-block-end: 16px;
}

.orders-table {
  border-collapse: collapse;
  
  th {
      text-align: left;
      font-weight: 600;
      font-size: 12px;
  }

  td {
      border-bottom: 1px solid $line-color;
      padding: .5rem .625rem .625rem 0;
      font-weight: 400;
      text-align: left;

      &.form-request-14{
        border: 1px solid blue;
      }
  }
  img{
    width: 100px;
    @include breakpoint(small down) { 
      width: 60px;
    }

    .product-sku {
      font-size: 16px;
      letter-spacing: 0.1em;
      margin-top: 5px;
    }
  }
}

.button-label{
  font-size: 14px;
  text-transform: uppercase;
}

.table-orders{
  border-collapse: collapse;
  th{
    text-align: left;
    min-width: 100px;
  }
}

.profile-returns{
  border-bottom: 1px solid $line-color;
  padding-bottom: 10px;
}

.dl-horizontal dt {
  margin-bottom: 5px;
  font-size: 16px;
}

.margin-orders-mobile{
  @include breakpoint(small down) { 
    margin-top:20px;
  }

}

.order-dimension{
  font-size: 16px;
  margin: 2px 0;
  text-transform: initial;
}

.verified-icon {
  position: absolute;
  width: 20px;
  top: 42px;
  right: 20px;
}