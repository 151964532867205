@import 'node_modules/foundation-sites/scss/foundation';
@import 'variables';

$flex: true;

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
  @include foundation-grid;
}
@else {
  @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

@if $flex {
  @include foundation-flex-classes;
}


@import 'typography';
@import 'mixins';
@import 'general';
@import 'inputs';

// components
@import 'components/header';
@import 'components/footer';
@import 'components/product';
@import 'components/menu';
@import 'components/cart-summary';
@import 'components/errors';
@import 'components/default';
@import 'components/defaultdate';
@import 'components/defaulttime';
@import 'components/nanoscroller';

// pages
@import 'pages/home';
@import 'pages/catalog';
@import 'pages/product';
@import 'pages/cart';
@import 'pages/profile';
@import 'pages/cms';
@import 'pages/checkout';
@import 'pages/login';
@import 'pages/contact';

// slick
$slick-font-family: 'slick-custom';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';
@import 'node_modules/slick-lightbox/src/styles/slick-lightbox';
@import '../fonts/slick/slick.css';

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $first-color;
    border-color: $first-color transparent $first-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}