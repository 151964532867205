#product-slider-nav .slick-slide {
    padding: 0 5px;
}

.product-container {
    margin: 24px 0 56px 0;
    @include breakpoint(small down) {
        margin: 16px 0;
    }
}

.sample-slick-item {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    position: relative;
    align-items: flex-end;
}

.product-slider {
    margin-bottom: 10px;
}

.delete-icon {
    color: crimson;
    position: absolute;
    right: 3px;
    top: 4px;
    transform: scale(1.4);
    cursor: pointer;
}

.height-58 {
    height: 58px;
}

.height-0 {
    height: 0px;
}

#product-slider-nav .slick-track .slick-current img {
    border-bottom: 1px solid $first-color;
    padding-bottom: 5px;
}

#product-slider-nav {
    .nav-slide.slick-slide.slick-current {
        border-bottom: 1px solid $first-color;
    }
}

.nav-slide {
    border-bottom: 1px solid $line-color;
    padding-bottom: 5px;
}

.list-tag {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include breakpoint(small down) {
        margin-top: 20px;
    }

    li {
        margin: 0 5px;
        @include breakpoint(small down) {
            margin: 0 2px 2px;
        }
    }

    a {
        @include lato-semibold;
        @include transition-basic;
        color: $dark-grey;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border: 1px solid $line-color;
        padding: 5px 15px 3px;

        &:hover {
            color: $black;
            border-color: $black;
        }
    }
}

.sample-image {
    &:hover {
        box-shadow: 1px 2px 12px rgba(49, 49, 49, 0.51);
    }
}

.product-info__title {
    @include barlow-medium;
    font-size: 35px;
    line-height: 48px;
    @include mb-8;
    margin-top: 0;
    text-transform: capitalize;
    @include breakpoint(small down) {
        font-size: 30px;
    }
    &.cart {
        @include barlow-medium;
        font-size: 18px;
        line-height: 24px;
    }
}

.product-info__subtitle {
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    letter-spacing: 0.05em;
    @include breakpoint(small down) {
        font-size: 11px;
    }
}
.product-info__descr {
    font-size: 18px;
    @include breakpoint(small down) {
        font-size: 16px;
    }
}

.product-info__price {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 0px 0;

    &.popup-cart {
        padding: 0;
    }

    &.list {
        padding: 16px 0 0 0;
    }
}

.product-info__single-price {
    @include lato-semibold;
    font-size: 22px;

    @include breakpoint(small down) {
        font-size: 19px;
    }

    span {
        text-transform: uppercase;
        font-size: 35px;
        line-height: 40px;
        @include barlow-semibold;

        &.discount {
            color: $dark-grey;
            font-size: 20px;
            margin-left: 10px;
            text-transform: inherit;

            &.linedthrough {
                text-decoration: line-through;
            }
        }
        &.list {
            font-size: 25px;
            line-height: 32px;
            display: inline-block;
        }
    }
    &.popup-cart {
        span.price-row {
            font-size: 18px;
        }
    }

    /*span:last-child {
      font-size: 32px;
    }
    */

    &.-light {
        opacity: 0.4;
    }
}

.product-info__size-table {
    text-align: center;
    margin: 20px 0 45px;

    a {
        @include lato-semibold;
        @include transition-basic;
        color: $dark-grey;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border: 1px solid $line-color;
        padding: 5px 15px 3px;

        &:hover {
            color: $black;
            border-color: $black;
        }
    }
}

.i-number-btn {
    border-top: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;
    background-color: $white;
    display: flex;
    min-width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 0 4px 4px 0;

    img {
        width: 14px;
    }
    &.i-number-minus {
        border-right: none;
        border-left: 1px solid $grey-2;
        border-radius: 4px 0 0 4px;
        height: 45px;
        background-color: $white;
    }
    &.i-number-plus {
        border-left: none;
        border-right: 1px solid $grey-2;
        height: 45px;
        background-color: $white;
    }
}

.qty-input-container {
    display: flex;
    align-items: initial;
    justify-content: left;
    position: relative;
    top: -1px;

    &.cart {
        margin-top: 0;
    }

    input[type="text"] {
        background-color: $white;
    }
}

.i-number {
    margin: 0;
    height: 45px;
    box-shadow: none;
    width: 70px;
    text-align: center;
    border: none;
    background-color: $white;
    cursor: default;
}

.qty-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    th {
        @include lato-medium;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.05em;
        padding: 8px 0px;
    }

    td {
        @include lato-medium;
        border-top: 1px solid $line-color;
        /* border-bottom: 1px solid $line-color; */
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        padding: 8px 0px;
        -ms-flex-align: center;
        align-items: center;
    }

    .availability {
        opacity: 0.4;
    }
}

.qty-max {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 1px solid $black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 8px;
    position: relative;

    &:active {
        top: 1px;
    }
}

.qty-table-resume {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    button {
        @include lato-semibold;
        background-color: $black;
        color: $white;
        width: 182px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        padding: 9px 0 7px;
        position: relative;

        &:active {
            top: 1px;
        }
    }

    span {
        @include lato-semibold;
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:first-child {
            font-size: 11px;
            padding-top: 5px;
        }
    }

    td {
        border-bottom: 1px solid $line-color;
        padding: 25px 0;

        &:first-child {
            opacity: 0.4;
        }
    }
}

.size-col-1 {
    width: 25%;
}

.size-col-2 {
    width: 25%;
}

.size-col-3 {
    width: 50%;
}

.price-total {
    text-align: center;
    padding: 18px 0 12px;

    span {
        @include lato-semibold;
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:first-child {
            font-size: 11px;
            margin-bottom: -6px;
        }

        &:last-child {
            font-size: 22px;
        }
    }
}

.related-title {
    text-align: center;
    color: $black;
    margin: 60px 0 40px;
    padding: 0 0 10px;
    @include breakpoint(small down) {
        font-size: 30px;
        margin: 50px 0 30px;
        padding: 0 0 6px;
    }
}

.list-colors-wrapper {
    text-align: center;
    border-bottom: 1px solid $line-color;
    padding: 25px 0 28px;

    p {
        @include lato-semibold;
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: -2px;
        @include breakpoint(small down) {
            margin-bottom: 10px;
        }
    }
}

.list-colors {
    li {
        display: inline-block;
        margin: 0 8px;
    }

    span {
        @include transition-basic;
        width: 58px;
        height: 5px;
        display: inline-block;
        @include breakpoint(small down) {
            width: 40px;
            height: 8px;
        }
    }

    a:hover,
    a.active {
        span {
            transform: scaleY(2.8);
        }
    }
}

.code-product {
    font-size: 14px;
    &.popup-cart {
        margin-bottom: 0;
    }
}

.gocheckout-sample {
    display: flex;
    justify-content: center;
}

.sample-empty {
    background: #d3d3d347;
    height: 90px;
    width: 100px;
}

.close-sample {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
    width: 20px;
}

//slider

#product-slider {
    .slick-center {
        opacity: 1;
    }

    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $first-color;
    }

    .slick-next:before,
    .slick-prev:before {
        color: $first-color;
        font-size: 40px;
    }

    .slick-prev {
        left: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-next {
        right: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-prev:before {
        content: url(/skins/current-skin/images/icons/freccia-sx-slider.svg);
    }

    .slick-next:before {
        content: url(/skins/current-skin/images/icons/freccia-dx-slider.svg);
    }
}

.product-details {
    @include breakpoint(small down) {
        font-size: 16px;
    }
    .detail.-right {
        @include lato-medium;
        font-size: 18px;
        line-height: 30px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }

    .detail p {
        margin-bottom: 2px;
        font-size: 18px;
        line-height: 29px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }
}

.quantity-cart {
    padding: 0px 18px;
}
//color swatch

.product-colors {
    li a {
        display: block;
        background-position: 50%;
    }
}

.product-colors {
    margin: 0 0 12px;
    padding: 0 0 14px;
    list-style: none;
    border-bottom: 1px solid $first-color;

    li {
        display: inline-block;
        padding: 2px;
        width: 13.692%;
        cursor: pointer;

        a {
            display: block;
            height: 45px;
            background-position: center center;
            cursor: pointer;
        }

        &.active a {
            box-shadow: inset 0 0 0 2px $first-color;
        }
    }
}

//banner campione
.container_banner-campione {
    background-color: $light-grey;
    padding: 19px 28px;
    @include breakpoint(small down) {
        padding: 19px 19px;
    }

    &.home {
        padding: 50px 28px;
    }
}
.title-banner-container {
    font-size: 26px;
    margin-bottom: 15px;
}
.description-banner-container {
    font-size: 18px;
    margin-top: 20px;
}
.banner-campione {
    padding: 17px;

    &.home {
        padding-top: 0px;
    }
}

.bounce {
    animation: bounceIn 0.7s;
}

@keyframes bounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    60% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

.container_banner-image {
    background-image: url(/skins/current-skin/images/product/tessuto-popup.jpg);
    background-position: center center;
}

.color-title {
    border: none;
    color: $black;
    font-size: 20px;
    padding: 1rem 0;
    font-family: caslon, Georgia, serif;
    font-style: normal;
    font-weight: 300;
}

.size-product {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        padding: 2px;
        width: 32%;

        @include breakpoint(small down) {
            width: 32%;
        }

        input[type="radio"] + label {
            background-color: $light-grey;
            border: 1px solid $light-grey;
            padding: 8px 0 6px;
            font-size: 14px;
            margin: 0;
            font-weight: 700;
            transition: 150ms;
            width: 100%;
            text-align: center;
            line-height: 1.8;
        }

        input {
            display: none;
        }

        input[type="radio"]:checked + label,
        input[type="radio"] + label:hover {
            background-color: $first-color;
            color: $white;
        }

        span {
            background-color: $light-grey;
            color: #989898;
            padding: 8px 0 6px;
            font-size: 14px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            display: inline-block;
            cursor: default;
            line-height: 1.8;
        }
    }
}

.title-size {
    font-size: 20px;
    margin-top: 15px;
}
.etichette-uso {
    height: 35px;
}

.detail > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// cart popup

#addToCartComplete {
    border-radius: 10px !important;
    overflow: hidden;

    .title {
        align-items: center;
        justify-content: center;
    }

    .price-row {
        font-size: 28px;
    }

    @include breakpoint(medium down) {
        overflow: auto;

        .title {
            justify-content: flex-start;
        }

        h3 {
            font-size: 18px;
            line-height: 24px;
        }

        .price-row {
            font-size: 22px;
            line-height: 24px;
        }
    }
}

.reveal-overlay {
    z-index: 110;
}

.reveal {
    &.product-popup {
        width: 800px;
        @include breakpoint(medium only) {
            width: 700px;
            top: 100px !important;
        }

        @include breakpoint(small only) {
            width: 320px;
            top: 0px;
            margin: 0 auto;
            height: auto;
            min-height: 0;
        }
    }
}

#priceReveal {
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-bottom: -6px;
    font-weight: 600;
}

.euro {
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-bottom: -6px;
    font-weight: 600;
}

//plus
.product-sheet-plus-container {
    display: flex;
    align-items: center;
    p {
        color: $dark-grey;
    }
    .title {
        font-size: 16px;
        text-transform: uppercase;
        @include lato-semibold;
    }
    img {
        width: 40px;
        height: 40px;
    }
}
.plus-border-bottom {
    border-bottom: 1px solid $grey-2;
    @include pb-16;
    @include mt-16;

    .paragraph {
        font-size: 14px;
        line-height: 24px;
    }
}

.accordion {
    background: none;
}

.product-sheet {
    .accordion {
        > li {
            border: 1px solid $grey-2;
            padding: 0 32px;
            border-radius: 4px;
            background-color: $white;
            @include mt-16;

            .accordion-title {
                color: $dark-grey;
                text-transform: uppercase;
                font-size: 20px;
                @include barlow-semibold;

                &:active,
                &:focus,
                &:hover {
                    color: $first-color;
                }
            }

            :last-child > .accordion-content:last-child {
                border-bottom: none;
            }
        }
    }

    .description {
        font-size: 16px;
        line-height: 24px;

        h2 {
            font-size: 28px;
            text-transform: none;
        }

        span,
        p,
        a,
        div,
        pre {
            font-size: 16px;
            line-height: 24px;
            @include lato-regular;
        }

        p {
            margin: 16px 0;
        }
        ul {
            padding-left: 24px;
            @include breakpoint(small down) {
                padding-left: 16px;
            }    
        }

        ul,
        ol {
            li {
                border: none;
                padding: 0;
                margin-top: 0px;
                margin-left: 16px;
            }
        }

        ul li {
            list-style-type: disc;
        }

        ol li {
            list-style-type: decimal;
        }

        table {
            width: 100%;
            border: 1px solid $dark-grey;
            border-radius: 3px;
            border-collapse: separate;
            border-spacing: 0;

            th,
            td {
                padding: 4px;
                text-align: center;
            }

            th {
                @include barlow-semibold;
                border-bottom: 1px solid $dark-grey;
            }

            th:not(:last-child) {
                border-right: 1px solid $dark-grey;
            }

            tr:not(:last-child) {
                td {
                    border-bottom: 1px solid $dark-grey;
                }

                td:not(:last-child) {
                    border-right: 1px solid $dark-grey;
                }
            }

            tr:last-child td:not(:last-child) {
                border-right: 1px solid $dark-grey;
            }
        }
    }
}

.product-item__img img + img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
}

.cta-image {
    background-size: cover;
    background-position: center center;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(small down) {
        height: 320px;
    }
}

.size-select option:not(.product-option) {
    display: none;
}

.vat-included {
    font-size: 14px;
    line-height: 24px;

    &.product {
        font-size: 16px;
    }
}

.retail-price {
    font-size: 16px;
    line-height: 24px;
    @include lato-semibold;
    color: $grey-1;
    &.product {
        font-size: 20px;
        line-height: 24px;
    }
}
