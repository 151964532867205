//Subscribe
.list {
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list-content p {
    flex: 1 0 auto;
}

.label-subscribe {
    position: absolute;
    top: 22%;
    text-align: center;
    width: 100%;
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    h3{
        font-size: 24px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    &.second {
        top: 29%;
    }

    p{
        text-shadow: 0 2px 5px $black;
        color: $white;
        margin: 20px 28px;
        font-size: 18px;
    }
}

.subscribe-home-blog {
    img {
        height: 158.14px;
        object-fit: cover;
        object-position: top center;
        width: 100%;
        border-radius: 4px;

        &.archive-blog {
            height: 34vh;
        }
    }
}


.home-hero__text {
    padding: 50px 0px;
    text-shadow: 0 1px 10px $black;

    &.newsletter{
        text-shadow: none;
        display: flex;
        justify-content: center;
        
        p {
            color: $white;
            @include lato-semibold;
    
            @include breakpoint(large down) {
                padding: 15px 30px;
            }
    
            @include breakpoint(medium down) {
                padding: 15px 30px;
                margin-bottom: 0px;
            }
        }
    }

    &.product {
        text-shadow: none;
        display: flex;
        justify-content: center;
        padding: 0;
        text-shadow: none;
    }

    @include breakpoint(large down) {
        padding: 42px 0px;
    }

    @include breakpoint(medium down) {
        padding: 42px 0px;
    }

    h2 {
        font-size: 30px;
        color: $white;
        
        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }

    p {
        color: $white;
        padding-top: 15px;

        @include breakpoint(large down) {
            padding: 15px 30px;
        }

        @include breakpoint(medium down) {
            padding: 15px 30px;
            margin-bottom: 0px;
        }
    }


    .btn.btn-secondary {
        padding-top: 30px;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }
 
}

.button-home_subscribe{
    padding: 15px 0;
}

.btn-register-home{
    width: 97%;
    &.professionista {
        width: 99%;
    }
}
.home-hero__text .date-landing-page{
    font-size:25px;
    padding-left: 8px;
    border-left:3px solid $first-color;
    margin-bottom: 0px;
    padding-top: 0px;

    @include breakpoint(small down) {
     font-size:21px;
    }

}

.cms-wrapper .home-hero__image .home-hero__text{
    p {
        @include breakpoint(medium down) {
            padding:0px;
            padding-top: 20px;
            margin-bottom:20px;
        }
    }

}


.margin-bottom-privato{
    margin-bottom:47px;
}

.home-hero__image {
    &.newsletter{
        background: linear-gradient(180deg, rgba(31, 30, 31, 0.3) 3.45%, rgba(31, 30, 30, 0.2) 46.82%, rgba(255, 255, 255, 0) 107.66%), url("/skins/current-skin/images/newsletter/newsletter.jpg");
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        height: 100%
    }
}

//landing page
.landing-hero__text {
    padding: 72px 0px;
    text-shadow: 0 1px 10px $black;

    @include breakpoint(large down) {
        padding: 42px 0px;
    }

    @include breakpoint(medium down) {
        padding: 42px 0px;
    }

    h2 {
        font-size: 30px;
        color: $white;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }

    p {
        color: $white;
        padding-top: 15px;

        @include breakpoint(large down) {
            padding: 15px 30px;
        }

        @include breakpoint(medium down) {
            padding: 15px 30px;
            margin-bottom: 0px;
        }
    }


    .btn.btn-secondary {
        padding-top: 30px;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }
 
}
.landing-hero__text .date-landing-page{
    font-size:25px;
    padding-left: 8px;
    border-left:3px solid $first-color;
    margin-bottom: 0px;
    padding-top: 0px;

    @include breakpoint(small down) {
     font-size:21px;
    }

}

.landing-hero__text{
    p {
        @include breakpoint(medium down) {
            padding:0px;
            padding-top: 20px;
            margin-bottom:20px;
        }
    }

}

.landing-hero__image {
    background-image: url("/skins/current-skin/images/home/alt-hero-image-1.png");
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
}

video {
    display: inline-block;
    vertical-align: baseline;
}

#play-button {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80px;
    height: 80px;
    cursor: pointer;

}
//sliders
.slick-list, .slick-slider {
    &.no-padding {
        padding: 0;
    }
    @include breakpoint (medium down) {
        padding: 0;
    }
}
#best-brands, .product-slider-best {
    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $first-color;
    }
}

#best-brands {

.slick-slide {
    opacity: 1;
    min-height: none;
    width:1080px;
    @include breakpoint (medium down) {
        opacity: 1;
    }

    }
    .slick-center {
        opacity: 1;
    }
    .slick-list, .slick-slider {
        padding: 0 56px;

        @include breakpoint (medium down) {
            padding: 0;
        }
    }
    .slick-next:before,
    .slick-prev:before {
        color: $first-color;
    }

    .slick-prev {
        left: 0px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
           display: none!important;
        }
        &::before {
            content: url(/skins/current-skin/images/icons/arrow-left.svg)!important;
         }
    }

    .slick-next {
        right: 0px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
            display: none!important;
         }
         &::before {
            content: url(/skins/current-skin/images/icons/arrow-right.svg)!important;
         }  
    }
    .slick-prev, .slick-next {
        box-shadow: 0px 2px 20px rgba(105, 105, 105, 0.2);
        border-radius: 50%;
        background-color: $white;
    }

    img {
        margin: 0 auto;
    }

}

.sample-slick {
    margin-right: 30px;
    .slick-next {
        right: -49px;
    };
    .slick-prev {
        display: none !important;
        left: 0;
    }
}

//img categorie

.img-categories{
    min-height: 160px;
    text-align: center;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
  }
  
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

//home slider 
.slider-background {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: center center;
    max-width: 100%;
    display: flex!important;

    &.right {
        justify-content: center;
    }
}

.banner-text-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @include ml-56;

    &.right {
        justify-content: flex-end;
        @include mb-24;
        @include ml-24;

        @include breakpoint (small down) {
           @include ml-24;   
         }
    }

    @include breakpoint (medium only) {
        @include ml-24;   
        @include mr-24;   
      }

    @include breakpoint (small down) {
       margin-left: 0;    
    }
}
.banner-text-bg {
    background-color: #ffffff42;
    padding: 24px;
    p.main-title {
        font-size: 50px;
        line-height: 56px;
        @include barlow-semibold;
        color: $black;
    }
}


#slider-home, .product-slider-best, .product-slider-cms {
    .slick-next:before,
    .slick-prev:before {
        color: $first-color;
    }

    .slick-prev {
        left: 8px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
           display: none!important;
        }
        &::before {
            content: url(/skins/current-skin/images/icons/arrow-left.svg)!important;
         }
    }

    .slick-next {
        right: 4px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
            display: none!important;
         }
         &::before {
            content: url(/skins/current-skin/images/icons/arrow-right.svg)!important;
         }  
    }
    .slick-prev, .slick-next {
        box-shadow: 0px 2px 20px rgba(105, 105, 105, 0.2);
        border-radius: 50%;
        background-color: $white;
    }
}

.main-title {
    font-size: 35px;
    line-height: 40px;
    text-transform: uppercase;
    color: $dark-grey;
}

//newsletter
#newsletter-subscribe {
    @include mt-24;

    .email-input {
        background: $white;
        color: $black;
        border-radius: 7px;
        height: 44px;
        @include mr-16;
    }

    .email-input::placeholder {
        color: $black;
        opacity: 1;
    }

    button[type=submit] {
        @include breakpoint(medium down) {
        @include mt-40;
        }
    }

    .btn-next {
        text-transform: capitalize;
        width: max-content;
    }
}

#newsletter-arrow {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
    transition: opacity .4s ease;

    img {
        width: 16px;
    }
}
  
.dropdown .is-dropdown-submenu a {
    line-height: 24px;
}