.footer-logo {
    text-align:center;
  
    img{
      width: 100px;
    }
    
    p {
      font-size:18px;
      @include barlow-medium;
      color: $black;
      }
}

.footer-links {
  padding: 16px;
  background-color: $light-grey;
    &.home {
      background-color: $white;
    }
    ul {
      list-style: none;
    }

    a {
      @include transition-basic;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      @include breakpoint(medium down) {
        @include mt-16;
      }

        &:hover {
          color: $first-color;
        }
    }
      img {
      width: 40px;
      height: 40px;
    }
    p {
      color: $dark-grey;
    }
  
}


.footer-bottom {
  background-color:$dark-grey;
  padding: 32px 0;
  border-bottom: 2px solid $first-color;
    ul{
      margin-top:20px;
    }
    a {
      color: $white;
    }

    p{
    color: $white;
    @include barlow-semibold;
    font-size: 16px;
    text-transform: uppercase;
    &.copyright {
      font-size: 12px;
      @include lato-regular;
    }

  }
}

.footer-right {
  border-right: 1px solid $light-grey;
}

.footer-left {
  border-left: 1px solid $light-grey;
  @include pl-32;
}

.cookie_policy {
  text-align: center;
  padding: 11px 0 10px;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  color:#fff;
  

    a {
      @include lato-regular;
      
    }
}
