// colors
$black: #323232;
$black-hover: #191919;
$white: #ffffff;
$dark-grey: #555859;
$dark-blue: 44566C;
$grey-1: #95999D;
$grey-2: #d3d3d3;
$light-grey: #F7F7F7;
$first-color: #0AACDC;
$first-color-hover: rgb(6, 138, 179);
$first-color-alpha: rgba(10, 171, 220, 0.411);
$grey-paypal: #eee;
$blue-facebook: #1877f2;
$blue-facebook-hover: #1269db;
$line-color: #e3e6ea;
$red: red;
$success: #1ea75a;
$box-shadow: rgba(34, 34, 34, 0.12);
$yellow-banner:#FFE82F;

$green-whatsapp:#3FC351;
// Slick slider
$slick-font-path: '/skins/einside/fonts/slick/';
$slick-loader-path: '/skins/einside/images/slick/';