.login-subscribe-container {
  padding: 50px 0 50px;
}

.login-title {
  font-size: 30px;
  margin: 0 0 30px;
  padding: 0 0 5px;
  text-align: center;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    font-size: 30px;
  }

    &.-small {
      font-size: 25px;
      color: $dark-grey;
    }
}

.login-link {
  @include lato-semibold;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.remember-me {
  margin-bottom: 16px;
}

//subscribe

.border-top-subscribe{
  border-top: 1px solid $dark-grey;
  @include pt-24;
}

.subscribe-tabs {
  .tabs-title {
    width: 50%;
    padding: 8px;
    a {
      text-align: center;
      @include barlow-medium;
      text-transform: uppercase;
      font-size: 18px;
      color: $dark-grey;
      border: 2px solid $grey-2;
      padding: 24px 0;
      &:focus,  &:hover, &[aria-selected=true] {
        background-color: $first-color;
        border: 2px solid $first-color;
        color: $white;
      }
    }
  }
  .tabs {
    border: none;
  }
  .tabs-content {
    border: none;
  }
}

.show-for-part-2 {
  display: flex;
  align-items: center;
}

#vies-container {
  height: 24px;
  margin: 0 0 16px;

  label {
    margin: 0;
  }
}

//facebook icon
.fb-icon{
  margin-right: 20px;
}
