// Banner
.main-banner{
  background-color:$yellow-banner;
  @include pt-8;
  @include pb-8;
  p{
    font-weight: 600;
    color: $black;
  }
}

// main header
.header-main {
  z-index: 100;
  background-color:#fefefe;
  border-bottom: 1px solid $line-color;
}

.header-main.sticky {
  position: sticky;
  width: 100%;
  top: 0;
}

//right part

.header-main__side {

  &:hover .profile-menu-drop {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
      
  }

  .profile-menu-drop {
      position: absolute;
      /* transition: all .5s; */
      left: -20px;
      right: auto;
      top: 25px;
      margin: 0 auto;
      background-color: #ffffff;
      width: 60px;
      pointer-events: none;
      text-align: center;
      padding: 20px 5px 5px 5px;
      z-index: 10;
      opacity: 0;
      transform: translateY(-110px);

      &.drop-language {
        left: -24px;
        width: 68px;
      }

      &.drop-price {
        left: -19px;
        width: 120px;
        background-color: $light-grey;
        border: 1px solid $dark-grey;
        width: 120px;
        text-align: center;
        padding: 8px 0;
        z-index: 20;
      }

      input {
        padding: 0 !important;
        width: auto !important;
        height: auto !important;
      }
  }

  input {
      background-color: #fff;
      width: 92%;
      color: $black;
      border: none;
      cursor: pointer;
      transition: all .15s;
      background-color: $light-grey;
      width: 120px;
      text-align: center;
      padding: 5px 0;

      &:hover {
          color: $first-color;
      }
  }

  a:hover {
      color: $first-color;

  }
}

.top-banner{
  background-color: $first-color;
    padding: 6px 32px;

    p {
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    }
}