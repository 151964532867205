:root {
    color: $black;
}

*,
body {
    @include lato-regular;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style-type: none;

    &:focus {
        outline: none;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.semibold {
    font-weight: 600;
}

.underline {
    text-decoration: underline;
}

//min width header
.min-width-900 {
    min-width: 900px;
}

.margin-header {
    @media screen and (max-width: 39.9375em) {
        margin-top: 0px;
    }
    @media screen and (min-width: 40em) {
        margin-top: 0px;
    }
}

.transition-in {
    opacity: 1 !important;
    transition: 1s;
}

.transition-out {
    opacity: 0 !important;
    transition: 1s;
}

.upper {
    text-transform: uppercase;
}

.error {
    border-color: $red !important;
    background-color: $light-grey;
}

.error-password {
    color: $white;
    background-color: $red;
}

.cms-wrapper {
    @include pb-48;
    @include pt-16;

    u {
        text-decoration: underline;
    }
}

.row {
    max-width: 80rem;
}

.img-24 {
    width: 24px;
    height: 24px;
}

.img-32 {
    width: 32px;
    height: 32px;
}

.txt-left {
    text-align: left;
}
.txt-right {
    text-align: right;
}
.txt-center {
    text-align: center;
}

.text-shadow-none {
    text-shadow: none;
}

.uppercase {
    text-transform: uppercase;
}
.align-items-center {
    align-items: center;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-content-center {
    justify-content: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

//arrow

.arrow-link,
.arrow-link-left {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    display: inline-block;
}

.arrow-link {
    &:after {
        content: "";
        background-repeat: no-repeat;
        width: 11px;
        height: 20px;
        background-size: contain;
        position: absolute;
        top: 11px;
        left: 16px;
        background-image: url(/skins/current-skin/images/icons/arrow-down-black.svg);
        transform: rotate(90deg);
    }
}

.arrow-link-blue {
    &:after {
        content: "";
        background-repeat: no-repeat;
        width: 11px;
        height: 20px;
        background-size: contain;
        position: absolute;
        top: 9px;
        left: 16px;
        background-image: url(/skins/current-skin/images/icons/arrow-left.svg);
    }
}
:after {
    box-sizing: border-box;
}

.arrow-link-left:before {
    content: "<";
    margin-right: 10px;
    right: -16px;
    top: 25px;
    font-size: 18px;
    color: $first-color;
}
:before {
    box-sizing: border-box;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

.breadcrumb {
    text-transform: uppercase;
    ul,
    li {
        font-size: 14px;
    }

    li {
        display: inline;
        a {
            font-size: 14px;
        }
    }

    li.after:after {
        content: ":";
        white-space: pre;
    }
}

ul li {
    font-size: 16px;
    padding-bottom: 5px;
}

// position relative
.relative {
    position: relative;
}

//section
.margin-section {
    margin: 56px 0;
    &.banner {
        @include breakpoint(small down) {
            margin-bottom: 112px;
        }
    }
}

.padding-section {
    padding: 32px;
    @include breakpoint(small down) {
        padding: 32px 16px;
    }
}

.linedthrough {
    text-decoration: line-through;
}

//space

.list-unstyled {
    list-style: none;
}

.width-100 {
    width: 100%;
}

.border {
    border: 1px solid $line-color;
}

.border-bottom-grey {
    border-bottom: 1px solid $grey-1;
    @include pb-16;
}

.border-bottom-white {
    border-bottom: 1px solid $white;
    @include pb-16;
}

.overflow-hidden {
    overflow: hidden;
}

//blog
.smooting-corners {
    border-radius: 4px;
}

// titolo e sottotitolo

.title-text-centered {
    justify-content: center;
    text-align: center;

    h1 {
        font-size: 40px;
        text-align: center;

        &.font-30 {
            font-size: 30px;
        }
    }

    h2 {
        font-size: 30px;
        text-align: center;
    }

    p {
        padding: 27px 0px 35px 0px;
        line-height: 1.7;
    }
}

.white {
    color: $white;
}

.dark-grey {
    color: $dark-grey;
}

.bg-dark-grey {
    background-color: $dark-grey;
}

.first-color {
    color: $first-color;
}

.red {
    color: $red;
}

.bg-gradient {
    background: #f7f7f7; /* Old browsers */
    background: -moz-linear-gradient(
        top,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @include breakpoint(medium down) {
        background: -moz-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    @include breakpoint(small down) {
        background: -moz-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}

//small divisor

.line-100 {
    width: 70px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $first-color;
}
.line-150 {
    width: 150px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $first-color;
}

.line-200 {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $first-color;
    margin: 10px 0px 20px 0px;
}

.line-full {
    width: 100%;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $grey-2;
}

// fix menu ancore

.cms-wrapper .anchor {
    top: -160px;
    left: 0;
    position: absolute;
    font-size: 0;
}

//embed video Youtube responsive

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

//padding pagine archivio
.padding-wrapper {
    padding: 10px 0 10px;
}

.search-result {
    font-size: 40px;
    color: $black;
    margin-bottom: 0px;
}

.main-label {
    @include lato-medium;
    font-size: 18px;

    &.popup-cart {
        font-size: 16px;
        margin-bottom: 4px;
    }
}

.height-100 {
    height: 100%;
}

.red-error {
    color: #c62828;
}

// label
label,
option {
    font-size: 14px;
    line-height: 16px;
    @include lato-medium;
    margin-bottom: 4px;
    text-transform: uppercase;
    color: $dark-grey;
}

//image

.icons-24 {
    width: 24px;
}

//margin top

.mt-8 {
    @include mt-8;
}

.mt-16 {
    @include mt-16;
}

.mt-24 {
    @include mt-24;
}

.mt-32 {
    @include mt-32;
}

.mt-48 {
    @include mt-48;
}

.mt-56 {
    @include mt-56;
}

.mt-8-mobile {
    @include breakpoint(small down) {
        @include mt-8;
    }
}

.mt-24-mobile {
    @include breakpoint(small down) {
        @include mt-24;
    }
}

.mt-24-tablet {
    @include breakpoint(medium down) {
        @include mt-24;
    }
}

.mt-32-tablet-up {
    @include breakpoint(medium down) {
        @include mt-32;
    }
}

//margin bottom

.mb-8 {
    @include mb-8;
}

.mb-16 {
    @include mb-16;
}

.mb-24 {
    @include mb-24;
}

.mb-32 {
    @include mb-32;
}

.mb-40 {
    @include mb-40;
}

.mb-56 {
    @include mb-56;
}

.icon24 {
    width: 24px;
    height: 24px;
}

//margin-right

.mr-8 {
    @include mr-8;
}

.mr-16 {
    @include mr-16;
}

.mr-24 {
    @include mr-24;
}

.mr-40 {
    @include mr-40;
}

//margin-left

.ml-8 {
    @include ml-8;
}

.ml-16 {
    @include ml-16;
}

.ml-24 {
    @include ml-24;
}

//padding left
.pl-24 {
    @include pl-24;
}

.pt-16 {
    @include pt-16;
}

input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
