.no-products-found {
  text-align: center;
  margin: 9vw 0 10vw;
  padding: 56px;
  @include breakpoint(small down) {
    margin: 56px 0 72px;
    padding: 32px;
  }

    h1 {
      font-size: 50px;
      line-height: 64px;
      @include mb-24;
      @include breakpoint(small down) {
        font-size: 35px;
        line-height: 40px;
      }
    }
}

.no-search-found {
  margin: 5vw;
  text-align: center;
}


.catalog-filter-wrapper {
  padding: 30px 0;
}

.catalog-sort {
  display: flex;
  align-items: center;

    span {
      @include lato-semibold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 3px 0 0 10px;
    }
}

.catalog-sort__list {
  list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;
    }

    .catalog-sort__svg {
      @include transition-basic;
      width: 20px;
    }

    a {
      @include transition-basic;
      display: flex;
      align-items: center;
      border: 1px solid $black;
      padding: 7px;

        &:hover,
        &.active {
          background-color: $black;

          .catalog-sort__svg path {
            fill: $white;
          }
        }
    }
}

.catalog-filter__btn {
  background-color: $first-color;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  width: 200px;
  padding: 10px 14px 8px;
  text-align: left;
  position: relative;
  @include breakpoint(small down) {
    width: 100%;
    margin-top: 20px;
  }

    &:focus {
      outline: none;
    }

    img {
      position: absolute;
      width: 13px;
      right: 11px;
      top: 11px;

        &.hide {
          display: none;
        }
    }
}


.layout-options__item {
  display: inline-block;
  margin-left: 14px;

    svg {
      width: 20px;
    }

    path {
      fill: $black;
    }

    input {
      display: none;

      &:checked + label,
      + label:hover {
         /* background-color: $black; */

          path {
            fill: $white;
          }
      }
    }

    label {
      @include transition-basic;
      margin: 0;
      display: flex;
      align-items: center;
      border: 1px solid $black;
      padding: 7px;
    }
}

.h1-tag {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  font-family: 'brandon-regular',Arial,Helvetica,sans-serif;
}

.filters-row {
  display: flex;
  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
  }
}

.filters-wrapper {

  padding-bottom: 30px;

}

.filters-title {
  @include lato-semibold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 20px;
  @include breakpoint(small down) {
    margin: 25px 0 10px;
  }
}

.catalog-filter__img {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 5px;
  cursor: pointer;

    img {
      width: 10px;

        &.hide {
          display: none;
        }
    }
}

.subfilters-list {
  margin-left: 25px;
  display: none;
}

.filters-checkbox {
  max-height: 255px;
  overflow: hidden;

   &.open {
     max-height: none;
   }
}

.filters-checkbox-btn {
  border-top: 1px solid $line-color;
  max-width: 350px;
  margin: 25px auto 0;
  cursor: pointer;
  text-align: center;

    img {
      width: 10px;

      &.hide {
        display: none;
      }
    }
}

.current-filter {
  @include lato-medium;
  display: block;
  font-size: 10px;
  letter-spacing: 0.1em;
  position: relative;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: $black;
  margin: 0px 4px 0px 3px;

    img {
      width: 10px;
      position: relative;
      top: -1px;
      margin: 0 5px;
    }
}

.color-checkbox {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  box-shadow: 1px 0px 3px 0px $box-shadow;
}

.current-filter-name {
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-left: 10px;
  /* margin-left: 20px; */
  @include lato-medium;
  color: $first-color;
}

.remove-current-filter {
  display: block;
  border: 1px solid $first-color;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px 3px;
  margin: 10px 20px 0 0;
  @include lato-medium;
}

.filter-separator {
  border-top: 1px solid $line-color;
  margin-top: 28px;
  padding-top: 25px;
  @include breakpoint(small down) {
    padding-top: 5px;
  }
}

.catalog-line {
  border: none;
  border-bottom: 1px solid $line-color;
  margin-bottom: 10px;
}

.promo-tag {
  @include lato-semibold;
  position: absolute;
  right: 0;
  top: 0;
  background-color: $first-color;
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  padding: 5px 20px 4px;
  z-index: 10;
}

.filters-trigger__minus {
  display: none;
}

.open-filter {

    .filters-trigger__plus {
      display: none;
    }

    .filters-trigger__minus {
      display: block;
    }
}


@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}

.filter-button {
  height: 40px;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 15px !important;
}

#arrow-filter {
  transition: 1s;
}

.column-button {
  display:flex;
  justify-content:flex-start;
  padding-top: 5px;
}

.rotated {
  transform: rotate(180deg);
}

//ordina per
.radio-layout {
  display: inline-block;
  border: 1px solid $black;
  padding: 10px;
  widows: 36px;

  &:hover {
    background-color: $line-color;
  }

  input {
    position: absolute;
    display: none;
  }

  input[type=radio]:checked+.btn-default {
    background-color: $light-grey;
  }

  label {
    margin: 0;
    padding: 0;
    font-size: 0;
    display: block;
  }

  img {
    display: block;
    vertical-align: initial;
  }
}

#catalog-container {
  position: relative;

  .catalog-spinner {
    display: none;
    z-index: 10000;
    position: fixed;
    top: 150px;
    width: 100%;
    height: 3px;

    @include breakpoint(small down) {
      top: 0;
    }

    &:after {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation: indeterminate-bar 2s linear infinite;
      animation: indeterminate-bar 2s linear infinite;
      width: 100%;
      height: 2px;
      background-color: $secondary-color;
      content: '';
      display: block;
    }
  }

  &.catalog-loading {
    .catalog-spinner {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: fixed;
      z-index: 10000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, 0.5);
    }
  }

  #sort-options {

    &:hover,
    &:focus,
    &.show-submenu {
      ul {
        display: block;
      }
    }
    ul.sort-dropdown {
      li {
        display: inline-block;
        margin-right: 24px;
        padding: 8px 0;
        color: $dark-grey;
      }
    }

      >li>a,
      li>span {
        text-align: left;
        padding: 5px 15px 5px 10px;
        font-weight: 400;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $white;
        text-transform: inherit;
        margin-top: -1px;
        white-space: nowrap;

        &:hover {
          background-color: $light-grey;
        }
      }
    }
  }

.visible-active{
  display: none !important;
}
.hidden-active{
  display: inline;
}

.contact-no-quantity {
  width: 100%;
  font-size: 15px;
  margin-top: 20px;
}

li.active > a {
  /* background: white;
  color:#fff; */
  .visible-active{
    display: inline !important;
  }
  .hidden-active{
    display: none !important;
  }
}

.filters {

  .filter-tag {

    padding: 5px 10px;
    position: relative;

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      min-width: 33%;
    }

    .scroll-view {
        height: 220px;
      }

      > ul {
        li > a > span{
          text-transform: capitalize;
          display: block;
          padding: 4px 10px;
          color: black;
          background: white;
          margin: 2px 0;

          &:hover {
            background: darken(grey, 5%) !important;
            color:#fff;
            text-decoration: none;
          }

          .visible-active{
            display: none;
          }
          .hidden-active{
            display: inline;
          }
        }

        li.active > a {
          .visible-active{
            display: inline;
          }
          .hidden-active{
            display: none;
          }
        }
      }
    }

    .filter-sublist {
      > li > a {
        padding-left: 12px;
      }

      > li.active > .toggle-subfilter {
        color: $white;
      }

      > li > .toggle-subfilter {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 2;
        display: block;
        width: 16px;
        height: 16px;
        text-align: center;
        .fa-plus{
          display:inline-block;
        }
        .fa-minus{
          display:none;
        }
      }

      > li.open > .toggle-subfilter {
        .fa-plus{
          display:none;
        }
        .fa-minus{
          display:inline-block;
        }
      }

      > li > .toggle-subfilter + a {
        padding-left: 24px;
      }

      > li > a {
        font-weight: bold;
        text-transform: uppercase;

        > li {
          text-transform: capitalize;
        }
      }

      > li > ul {
        display: none;
      }

      > li.open > ul {
        display: block;
      }
    }

    .filter-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
      font-size: 16px;
      padding: 0 10px;
      margin: 0;
      line-height: 30px;
      background: $dark-grey;
      cursor: pointer;
      width: fit-content;
      @include lato-regular;
      font-weight: 100;
      width: 100%;

      &:hover, &:active{
        background-color: $dark-grey;
      }
    }
  
    &.open{
      .filter-name{
        border: 1px solid #000000 !important;
        span.fa{
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
          transform: rotate(-180deg);
        }
      }
    }
  
    .filter-dropdown{
      display:none;
      position: absolute;
      top: 50px;
      left: 10px;
      right: 10px;
      z-index: 1;
      background: white;
      padding: 0px 8px 8px 8px;
      border: grey 1px solid;
      z-index: 10;
      min-width: 200px;

      @media screen and (max-width: 63.9375em) {
        top: auto;
      }
  
      .btn{
        display: block;
        margin-top: 5px;
        background-color: $dark-grey;
      }
    }
}


//catalog banner catalog

.catalog-banner-img{
  height: 250px;
}

.layout-grid-img{
  width: 18px;
}

.number-select{
  border-bottom: 1px solid $dark-grey;
  @include lato-medium;
  padding: 7px 0px;
}

.fa-check:before {
  content: "\f00c";
  color: $white;
  text-shadow: 0px 0px 1px $dark-grey;
}

.img-search-error {
  width: 31px;
  margin: 9px 20px;
}

//pagination

.pagination {
  @include mt-24;
  @include mb-56;
    li{
      @include breakpoint(small down) {
        display: inline-block;
      }
    }
}

ul.pagination a {
  @include breakpoint(small down){
    width: 31px;
    height: 31px;
  }
}


.row-filter{
  justify-content:space-between;
  
  @include breakpoint(medium down) {
    margin-top:15px;
  }
  
}

.sort-options {
  display: flex;
  align-items: center;
}

.layout-options .radio-layout.sort-options-mobile {
  position: relative;
  transition: all .15s ease-in-out;
  margin: 0;
  border: 1px solid $grey-2;
  padding: 8px;
  margin-bottom: 0;
  top: 3px;
  margin-right: 8px;
  position: relative;

  ul {
    padding: 0;
    position: absolute;
    right: -20px;
    top: 32px;
    width: auto;
    display: none;
    z-index: 5;

    li {
      display: block;
    }
  }

  &:hover,
  &:focus,
  &.show-submenu {
    ul {
      display: block;
    }
  }

  .sort-dropdown {
    box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.2));
    background-color: $white;

    >li>a,
    li>span {
      text-align: left;
      padding: 5px 15px 5px 10px;
      font-weight: 400;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
      text-transform: inherit;
      margin-top: -1px;
      white-space: nowrap;

      &:hover {
        background-color: $grey-2;
      }
    }
  }
}


.sort-by {
  @include lato-semibold;
  text-transform: uppercase;
  @include mr-16;
}


.layout-options__item {
  display: inline-block;
  margin-left: 14px;

    svg {
      width: 20px;
    }

    path {
      fill: $black;
    }

    input {
      display: none;

      &:checked + label,
      + label:hover {
        background-color: $black;

          path {
            fill: white;
          }
      }
    }

    label {
      @include transition-basic;
      margin: 0;
      display: flex;
      align-items: center;
      border: 1px solid $black;
      padding: 7px;
    }
}

.sort-active {
  color: $first-color;
  @include lato-semibold;
}

.layout-options  {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  label {
    transition: all .15s ease-in-out;
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid $grey-2;
    padding: 8px;
  }

  input+label:hover, .layout-options input:checked+label {
    background-color: $grey-2;
  }

  .radio-layout {
    display: inline-block;
    border: none;
    padding: 0;
  }
}

.bg-light-grey {
  background-color: $light-grey;
  padding: 32px 0;
}

.border-catalog {
  border-bottom: 1px solid $first-color;
  @include pb-16;
}

.catalog-description-list {
  @include breakpoint(medium down) {
    @include mt-24;
  }
}

.product-name-container {
  min-height: 77px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .code-product {
    @include mt-8;
  }
}

.product-name {
  @include barlow-medium;
  font-size: 18px;
  line-height: 24px;

  &.profile {
    text-transform: initial;
  }
}

#search-keyword {
  @include barlow-semibold;
  color: $first-color;
}

.no-result {
  line-height: 48px;
}

.catalog-title {
  font-size: 35px;
  line-height: 40px;
  text-transform: uppercase;
  color: $dark-grey;
  text-align: left;
}

.catalog-cover {
  padding: 0 16px;
}

.picker-catalog-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  &:after {
    content: url(/skins/current-skin/images/icons/arrow-down-white.svg);
    width: 12px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 7px;
  }

  &.ie {
    &::after {
      display: none;
    }
  }
}

.picker-catalog-tag4 {
  height: 100%;
  background: rgb(38,186,232) !important;
  color: white;
  width: 160px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//search result

.search-result{
  font-size: 25px;
  color: $dark-grey;
  text-transform: uppercase;
  @include barlow-semibold;
}

.number-results{
  color: $grey-1;
  font-size: 16px;
  text-transform: initial;
}

#results-search {
  color: $first-color;
  @include barlow-semibold;

}

span.price.list.option.save-price-row {
  font-size: 18px;
  line-height: 24px;
}