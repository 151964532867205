.main-menu {

  ul {
    list-style: none;
    text-align: center;
  }

  > ul > li {
    display: inline-block;
    padding: 8px 24px; 
    &:first-child {
      padding: 8px 24px 8px 0;
    }

    > a {
      font-size: 16px;
      @include lato-semibold; 
      display: inline-block;
      position: relative;
      z-index: 11;
      text-transform: uppercase;
      color: $white;
    
      &:hover::after {
        content: '';
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 3px;
        background-color: $first-color;
      }
      
    }

    &:hover {
/*
      a::after {
        opacity: 1;
      }
      */

      .secondary-menu-container {
        display: block;
      }

    }
  }

  img {
    width: 24px;
  }
}

.secondary-menu-container {
  display: none;
  position: absolute;
  background-color: $white;
  margin: 5px auto;
  box-shadow: 0px 2px 20px rgba(105, 105, 105, 0.2);
  /* padding: 42px; */
  z-index: 10;

  ul li{
    text-align: left; 
  }

    .column-block {
      margin:0;

      .content-img {
        width: 192.8px;
        height: 192.9px;
        overflow: hidden;

        div.img {
          height: inherit;
          background-size:cover;
          background-repeat: no-repeat;
          background-position: 50%;
          transition: all .3s ease-out;
        }
      }

      a:hover {
        div.img { transform: scale(1.1); }
      }

      h4 {
        @include lato-regular;
        text-align: left;
        margin-top: 16px;
      }
    }
}


.secondary-menu__title,
.secondary-menu__title a {
  @include lato-medium;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: left;
}

.secondary-menu-item {
  a {
    display: flex;
    align-items: center;
  }

  &.mobile {
    font-size: 18px;
    line-height: 24px;
    &:after {
      content: url(/skins/current-skin/images/icons/arrow-right-black.svg);
      position: absolute;
      @include pr-8;
      width: 18px;
      right: 9px;
    }
  }

  img {
   @include mr-8;
   width: 24px;
   height: 24px;
   &.menu-mobile-arrow {
    right: 10px;
    width: 10px;
    top: 29px;
    position: absolute;
  }
  }
}

.secondary-menu-list 
{
  li {
    @include mb-8;
    a {
      padding: 8px;
    }
  }
}

//menu mobile
#offCanvasMenu {

  > div {
    background-color: $light-grey;
    @include breakpoint(medium down) {
      padding: 40px 0px;
    }
  }

  .off-menu-image {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

      img {
        width: 18px;
        height: 18px;
        cursor:pointer;
        transition: all 150ms ease;
        &:hover {
          opacity: 0.7;
        }
      }
  }

  .off-menu-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation-name: mobile-menu-fade;
    animation-duration: .7s;
    animation-fill-mode: forwards;

    &.js-submenu {
      display: none;
      margin-top: 20px;
    }

    &.js-desktop-menu {
      display: flex;
      @include breakpoint(small down) {
        display: none;
      }
    }

    &.js-submenu-open {
      display: flex;
    }
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    margin-bottom: 0px;

     a {
       font-size:18px;
       line-height: 24px;
       display: block;
       opacity: 0.6;
       transition: opacity 150ms ease;
       @include breakpoint(small down) {
         padding: 0;
         opacity: 1;
       }
       &:hover {
         opacity: 1;
       }
     }

  }

  $off-canvas-width-small: 100vw;
  $off-canvas-width-medium: 650px;
  @include breakpoint (medium down) {
    &.off-canvas {
      width: $off-canvas-width-medium;
      transform: translateX(-$off-canvas-width-medium);
      &.is-open {
        transform: translate(0, 0);
      }
    }
  }
  @include breakpoint (small down) {
    &.off-canvas {
      width: $off-canvas-width-small;
      transform: translateX(-$off-canvas-width-small);
      &.is-open {
        transform: translate(0, 0);
      }
    }
  }

  .off-canvas.is-transition-overlap.is-open {
    box-shadow: 0 0 10px hsla(0,0%,1%,0.2);
}

  .js-mobile-menu-back {
    display: none;
  }

  .js-mobile-menu-back-sub {
    display: none;
  }

  .js-open-submenu{
    position: relative;

    &::after {
      content: url(/skins/current-skin/images/icons/arrow-right.svg);
      width: 7px;
      height: auto;
      position: absolute;
      right: 0;
      top: 1px;
    }
  }

  .js-mobile-menu.off-menu-text{
    ul li{
      border-top: 1px solid $grey-2;
      border-bottom: 1px solid $grey-2;
      margin-top: -1px;
      padding: 24px;
    }

    & li.no-border-top{
      border-top: none;
    }
    & li.no-border{
      border: none;
    }
  }

  .js-submenu.off-menu-text{
    ul li {
    border-top: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;
    margin-top: -1px;
    padding: 24px;
    }
    & li.no-border{
      border: none;
    }
  }

  .js-mobile-menu {
    @include mt-24;
    display: flex;
  }

}

.header-main__flex {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.header-first {
  background-color: $grey-2;
  padding: 4px 0;
}
.header-second {
  background-color: $dark-grey;
}

.menu-first-level {
  background-color: $first-color;
}

.header-main__side {
  align-self: center;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  @include breakpoint(medium down) {
    justify-content: center;
  }

  &.center {
    justify-content: center;
  }

    &.-right {
    text-align: right;
    width: 100%;
    justify-content: flex-end;
    }
  .call-center {
    font-size: 14px;

    color: $dark-grey;
  }
}

.header-utilities__cart {
  img{
    position: relative;
    top: -1px;
  }
}

.header-utilities__cart.inline-block .full-cart{
  width: 22px;
  top: -3px;
}

.search-icon {
  position: absolute;
  top: 9px;
  width: 20px;
  height: 20px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
}

.header-main__flex input#mainsearch[type='search']  {
  margin: 0;
  margin-bottom: 0;
  border: 1px solid $first-color;
}
.header-utilities__space {
  margin: 0 6px;
  display: inline-block;

  .img-24{
    width: 24px;
    height: 24px;
  }
  
  .img-32{
    width: 32px;
  }
}

.header-main__center {
  text-align: center;
}

.header-main__logo {
  width: 180px;
  height: 69px;
  padding: 16px 0px;

  @include breakpoint(small down) {
    width: 160px;
    height: 30px;
    padding: 0;
  }
}

.header-utilities__language-dropdown {
  position: relative;
  cursor: pointer;

    span.language {
      position: relative;
      padding-right: 12px;
      top: 3px;
      color: $dark-grey;

        &::after {
          content: "";
          background-image: url(/skins/current-skin/images/icons/arrow-down-black.svg);
          background-repeat: no-repeat;
          width: 26px;
          height: 6px;
          position: absolute;
          right: 0;
          top: 10px;
          left: 80px;
      }
    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      background-color: $light-grey;
      border: 1px solid $dark-grey;
      width: 120px;
      text-align: center;
      top: 23px;
      left: -25px;
      padding: 5px 0 5px;
      z-index: 10;
    }

    &:hover {

      ul {
        display: block;
      }
    }
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px hsla(0,0%,4%,.2);
}

.menu-icon-left {
  width: 21px;
  margin-right: 5px;
}

.off-canvas{
  background-color: $light-grey;
}


//megamenu
.menu-columns{
  padding: 42px;
  border-right: 1px solid $dark-grey;
  min-height:350px;
    &.first{
      background-color: $white;
      border: 1px solid $light-grey;
    }
    &.second{
      background-color: $light-grey;
    }
    &.third{
      background-color: $dark-grey;
    }
    &.fourth{
      background-color: $dark-grey;
    }
    &.image{
      background-color: $dark-grey;
      border-right: none;
    }
}

.logout{
  font-size: 18px;
  padding: 0px;
  height: 100%;
}

.search-container {
  display: inline-grid;
  align-items: center;
  #mainsearch {
    margin: 0;
    border-radius: 6px;
    padding-left: 180px;
    @include breakpoint(medium down) {
      padding-left: 161px;
      padding-right: 53px;
    }
    border-color: rgb(38,186,232);
  }
}

#mainsearch-mobile {
  margin: 0;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 53px;
  border-color: rgb(38,186,232);
}

.mobile-category-picker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 16px 0;
  width: calc(100vw - 16px);

  .category {
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    color: $white;
    min-width: max-content;
    border: 1px solid $light-grey;
    background-color: $dark-grey;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.selected {
      cursor: default;
      border-color: $first-color;
      background-color: $first-color;
    }
  }
}

.header-container {
  padding: 8px 0;

  @include breakpoint(medium only) {
    padding: 8px 0;
  }
}

//main menu 
.menu {
  width: 400px;
}

.dropdown.menu.vertical>li.opens-right>a:after {
  content: url(/skins/current-skin/images/icons/arrow-right.svg);
  border-left-style: none;
  border: none;
  @include pr-8;
}

.is-dropdown-submenu-parent.opens-right {
  :hover {
    @include lato-semibold;
    color: $first-color;
  }
}

a.secondary-menu-item {
  display: flex;
  align-items: center;
}

.dropdown.menu>li.is-active>a, .menu>li.is-active>a {
  background-color: $first-color-alpha; 
  p {
    @include lato-semibold;
    color: $first-color;
  }
}


.dropdown.menu.vertical>li.opens-right>.is-dropdown-submenu {
  border: none;
  border-left: 1px solid $first-color;
  box-shadow: 0px 0px 23px rgba(105, 105, 105, 0.2);
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
  margin-top: -6px;
}


//mobile meu
.vertical.dropdown.menu.menu-mobile {
  width: 100%;
}

.mobile-menu-title {
  padding: 0 24px;
}

#mobile-menu-title {
  font-weight: 600;
  font-size: 20px;
}