#cart-container {
  @include mb-56;
  @include pt-32;
    @include breakpoint(medium) {
      @include pt-40;
  }

  @include breakpoint(small) {
    padding-top: 0px;
}
}
.cart-title {
  color: $dark-grey;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 32px;
  @include pb-8;
  @include mb-16;
  margin-top: 0;
  border-bottom: 1px solid $grey-1;
  &.summary {
    font-size: 25px;
  }
  @include breakpoint(small down) {
    @include mt-32;
    margin-top: 0;
  }
}

.cart-product {
  border-bottom: 1px solid $line-color;
  margin-bottom: 35px;
}

.cart-product__title {
  font-size: 20px;
  @include breakpoint(medium down) {
    margin-top: 20px;
  }
}

.cart-product__subtitle {
  font-size: 16px;
  display: block;
  margin: 2px 0;
}

.btn-cart-remove {
  border: 1px solid $grey-2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  @include ml-8;

    img {
      width: 24px;
    }
}

.cart-product__buttons {
  text-align: center;
  padding: 35px 0;

    button {
      @include lato-semibold;
      background-color: $black;
      border: 1px solid $black;
      color: $white;
      width: 182px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .1em;
      padding: 9px 0 7px;
      position: relative;
      margin: 0 5px;

        &.-light {
          color: $black;
          background-color: $white;
        }

        &:active {
          top: 1px;
        }
    }
}

.cart-product__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 30px;

    span {
      @include lato-semibold;
      display: block;
    }

    small {
      font-weight: bold;
      text-decoration: line-through;
      color: $dark-grey;
    }
}

.cart-product__price-label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .05em;
}

.cart-product__price-big {
  font-size: 22px;
  margin-top: -5px;
}

.cart-resume {
  padding: 32px;
  background-color: $light-grey;
  @include breakpoint(small down) {
    padding: 32px 8px;
  }
}

.cart-resume__top {
    p {
      @include lato-semibold;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      margin-bottom:0px;

      &.right{
        text-align:right;
      }

        &.-light {
          color: $dark-grey;
          @include lato-medium;
        }
    }

    &.-right {
      text-align: left;

        p {
          font-size: 16px;
          line-height: 24px;
        }
    }
}

.coupon-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

    > * {
      width: 50%;
    }

    input {
      margin: 0 10px 0 0;
      height: 40px;
      box-shadow: none;
      border-color: $line-color;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.04em;
      font-size: 14px;
      padding: 8px 8px 6px;
      color: $black;
    }

}

.coupon-label {
  font-size: 16px;
  margin-bottom: 4px;
  transition: all .15s ease-in-out;
  top: 6px;
  left: 14px;
  text-transform: uppercase;
  color: $black;
  font-size: 14px;
}

.country-select {
  @include lato-semibold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 12px;
  height: 34px;
  padding-left: 11px;
  border-color: $line-color;
}

.cart-total {
  @include pb-16;
  text-align: left;
  display: flex;
  align-items: center;


    span {
      @include lato-semibold;
      display: block;
    }
}

.cart-total__label {
  text-transform: uppercase;
  font-size: 18px;
  text-align: left;
  @include barlow-semibold;
}

.cart-total__price {
  font-size: 18px;
  text-align: right;
  @include barlow-semibold;
}

.select-carrier {
  margin: 0 0 15px;
}

.cart-resume__space {
  margin: 0 0 8px;
}

.cart-dimension{
  font-size: 16px;
  margin: 2px 0;
}
.cart-update .qty-input-container{
  margin-top: 3px;
}

.description-product_cart{
  display: flex;
  align-items: center;
}

.share-button-div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.share-button {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.whatsapp {
      background: $green-whatsapp;
    }
    &.mail {
      background: $first-color;
    }
    &.facebook {
      background:$blue-facebook;
    }
    > a > img {
      width: 24px;
      margin-bottom: 2px;
    }
}

.reveal {
  &.vat {
    @include breakpoint(small down) {
      height: 70%;
      min-height: 0;
      top: 20%!important;
    }

  }
}

.flex {
  display: flex;
}

.reveal {
  border-radius: 4px;
}

.popupShareTitle {
  text-align: center;
  font-size: 23px;
}

.popupShareLink {
  border: 1px solid $line-color;
  padding: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: none;
}

.popupShareLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $first-color;
  color: $white;
}

.cart-price {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include breakpoint(small down) {
    justify-content: flex-start;
    @include mt-16;
  }
  p {
  font-size: 18px;
  @include barlow-semibold;

  @include breakpoint(small down) {
    font-size: 25px;
    line-height: 32px;
  }
  }
}

.empty-cart {
  display: flex;
  justify-content: flex-end;

  @include breakpoint(small down) {
    justify-content: start;
    @include mt-16;
  }
}

.cart-select-option {
  font-size: 14px;
  @include lato-semibold;
  text-transform: capitalize;
}

.radio-label {
  font-size: 14px;
}

.cap_change {
  height: 3.375rem;
  box-shadow: none !important;
  border-color: #e3e6ea !important;
  font-weight: 700;
  letter-spacing: .04em;
  font-size: 14px;
  padding: 8px 8px 6px;
  color: #323232 !important;
}

.info-vat {
  width: 16px;
  margin-left: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

.vat-title {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  margin: 10px 0 20px 0;
}

.vat-title-2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.vat-subtitle {
  text-align: center;
}

.vat-line {
  height: 1px;
  background: $dark-grey;
  margin: 20px 10%;
}

.vat-post {
  text-align: center;
  margin-top: 40px;
  font-size: 12px;
}

.close-modalvat {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}