.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include barlow-medium;
}

.checkout-active {
  border-bottom: 1px solid $line-color;
  padding: 15px 0 7px;
  margin: 0 0 35px;
  color: $black;
  font-size: 30px;
}


.checkout-step-title {
  border: 1px solid $first-color;
  border-radius: 3px;
  background-color: $white;
  padding: 8px 16px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
  @include mb-24;
  @include mt-24;
  font-size: 14px;
  color: $first-color;
  @include barlow-semibold;

  &.next {
    border: 1px solid $grey-2;
    opacity: .5;
    color: $grey-2;
  }
}


.checkout-step-title-done {
  border: 1px solid $grey-2;
  padding: 8px 16px 7px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
}

.cart-summary-container {
  background-color: $first-color;
  padding: 0;

    .checkout-step-title {
      background-color: $first-color;
      border-color: $first-color;
    }

    .cart-summary {
      padding: 0 20px 14px;
      font-size: 14px;
    }
}


.checkout-buttons {
  margin: 0 0 40px;

    .btn {
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}

.payment-label {
  margin: 24px 0 8px;
  line-height: 43px;
}

.btn-satispay {
    padding: 0;
    background-color: #ff4438;
    border-radius: 4px;

    img {
        height: 40px;
    }
}